<template>
  <PermissionForm mode="Ubah" module="Permission"> </PermissionForm>
</template>

<script>
import PermissionForm from './form';

const PermissionUpdate = {
  name: 'PermissionUpdate',
  components: { PermissionForm },
};

export default PermissionUpdate;
</script>
